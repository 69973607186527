import { Paragraph, YStack } from '@mps/ui'
import React, { useEffect } from 'react'

export type RedirectProps = {
  url: string
  onRedirect?: () => void
}

export const Redirect = ({ url, onRedirect = () => {} }: RedirectProps) => {
  console.log('Redirect', { url })

  useEffect(() => {
    if (url) {
      console.log('redirect...', { url })
      window.open(url, '_self')
    }
  }, [url])

  return (
    <YStack jc="center" ai="center" minHeight="60vh">
      <Paragraph fontSize="$8">🚫 Redirection...</Paragraph>
    </YStack>
  )
}
