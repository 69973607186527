import {
  MaskOptions,
  addChildren,
  applyMask,
  createStrengthenMask,
  createTheme,
  createWeakenMask,
  skipMask,
} from '@tamagui/create-theme'
import {
  ColorName,
  SubTheme,
  allThemes,
  baseThemes,
  darkTemplate,
  getAltThemes,
  getComponentThemes,
  lightTemplate,
  palettes,
  transparent,
} from './helpers/themeHelpers'
import { colorTokens, darkColors, lightColors, tokens } from './tokens'
// import { addChildren } from '@tamagui/create-theme'

// const light = createTheme({
//   ...tamaguiThemes.light,
//   background: tokens.color.perano50,
//   backgroundSoft: tokens.color.perano25,
//   backgroundStrong: tokens.color.perano200,
//   color: 'black',
//   color2: tokens.color.perano200,
//   color3: tokens.color.perano300,
//   color4: tokens.color.perano400,
//   color5: tokens.color.perano500,
//   color6: tokens.color.perano600,
//   color7: tokens.color.perano700,
//   color8: tokens.color.perano800,
//   color9: tokens.color.perano900,
//   placeholderColor: '#9D9D9D',
//   backgroundHover: tokens.color.perano200,
//   borderColor: tokens.color.perano300,
//   borderColorHover: tokens.color.perano400,
//   // destructiveBackground: '#FF8C8C',
//   // destructiveColor: '#FFDADA',
//   // destructiveBorder: '#E95656',
// })

// const secondary = createTheme({
//   ...light,
//   background: tokens.color.perano100,
// })

// type BaseTheme = typeof light

// const dark: BaseTheme = {
//   ...tamaguiThemes.dark,
//   background: tokens.color.perano1300,
//   backgroundStrong: tokens.color.perano1200,
//   color: tokens.color.perano700,
//   placeholderColor: tokens.color.perano600,
//   backgroundHover: tokens.color.perano1000,
//   backgroundPress: tokens.color.perano1100,
//   // backgroundFocus: tokens.color.gray4Dark,
//   // borderColor: tokens.color.gray3Dark,
//   // borderColorHover: tokens.color.gray4Dark,
//   // color: '#ddd',
//   // colorHover: tokens.color.gray11Dark,
//   // colorPress: tokens.color.gray10Dark,
//   // colorFocus: tokens.color.gray6Dark,
//   // shadowColor: tokens.color.grayA6,
//   // shadowColorHover: tokens.color.grayA7,
// }

// HEADING

// const light_Heading: BaseTheme = createTheme({
//   ...light,
//   color: tokens.color.perano1000,
// })

// const dark_Heading: BaseTheme = createTheme({
//   ...dark,
//   color: tokens.color.perano500,
//   backgroundHover: tokens.color.perano400,
// })

// const light_H1: BaseTheme = light_Heading
// const light_H2: BaseTheme = light_Heading
// const light_H3: BaseTheme = light_Heading
// const dark_H1: BaseTheme = dark_Heading
// const dark_H2: BaseTheme = dark_Heading
// const dark_H3: BaseTheme = dark_Heading

// // INPUT
// // const Input: BaseTheme = createTheme({
// //   ...light,
// //   background: '#ffffff',
// //   borderColorFocus: tokens.color.perano500,
// // })

// const light_Input: BaseTheme = createTheme({
//   ...light,
//   background: '#ffffff',
//   borderColorFocus: tokens.color.perano500,
//   backgroundHover: '#ffffff',
// })

// const dark_Input: BaseTheme = createTheme({
//   ...dark,
//   background: tokens.color.perano400,
//   color: tokens.color.perano1000,
// })

// // SELECT

// const light_Select: BaseTheme = createTheme({
//   ...light,
//   background: '#ffffff',
//   borderColorFocus: tokens.color.perano500,
//   backgroundHover: '#ffffff',
// })

// const dark_Select: BaseTheme = createTheme({
//   ...dark,
//   background: tokens.color.perano400,
//   color: tokens.color.perano1000,
// })

// // BUTTON

// const light_Button: BaseTheme = createTheme({
//   ...light,
//   backgroundSoft: tokens.color.perano300,
//   background: tokens.color.perano900,
//   color: tokens.color.perano50,

//   backgroundHover: tokens.color.perano700,
//   backgroundPress: tokens.color.perano800,

//   // backgroundFocus: tokens.color.perano700,
//   // borderColor: tokens.color.perano400,
// })

// const dark_Button: BaseTheme = createTheme({
//   ...dark,
//   // background: tokens.color.perano500,
//   // backgroundHover: tokens.color.perano500,
//   // backgroundPress: tokens.color.perano600,
//   // backgroundFocus: tokens.color.perano700,
//   // borderColor: tokens.color.perano400,
// })

// const light_Card: BaseTheme = createTheme({
//   ...light,
//   backgroundSoft: tokens.color.perano50,
//   background: tokens.color.perano100,
//   borderColorHover: tokens.color.perano700,
//   borderColorFocus: tokens.color.perano500,
// })

// const dark_Card: BaseTheme = createTheme({
//   ...dark,
//   background: tokens.color.perano1200,
// })

// // LIST ITEM

// const light_ListItem: BaseTheme = createTheme({
//   ...light,
// })

// const dark_ListItem: BaseTheme = createTheme({
//   ...dark,
//   backgroundHover: tokens.color.perano400,
// })

// const red: BaseTheme = createTheme({
//   ...light,
//   background: '#FFDADA',
//   backgroundStrong: '#FF756C',
//   color: '#E95656',
//   borderColor: '#FF8C8C',
// })

// const red_Button: BaseTheme = createTheme({
//   ...light,
//   background: '#FF8C8C',
//   backgroundStrong: '#FF756C',
//   color: 'white',
//   borderColor: '#E95656',
//   borderColorHover: '#E95656',
//   backgroundHover: '#FF756C',
//   backgroundPress: '#E95656',
// })

// const green: BaseTheme = createTheme({
//   ...light,
//   background: tokens.color.magicMint100,
//   backgroundSoft: tokens.color.magicMint25,
//   backgroundStrong: tokens.color.magicMint200,
//   color: tokens.color.magicMint700,
//   placeholderColor: '#9D9D9D',
//   backgroundHover: tokens.color.magicMint200,
//   borderColor: tokens.color.magicMint400,
// })

// const green_Button: BaseTheme = createTheme({
//   ...light,
//   backgroundSoft: tokens.color.magicMint300,
//   background: tokens.color.magicMint900,
//   color: tokens.color.magicMint50,
//   color2: tokens.color.magicMint200,
//   color3: tokens.color.magicMint300,
//   color4: tokens.color.magicMint400,
//   color6: tokens.color.magicMint600,
//   color9: tokens.color.magicMint900,
//   backgroundHover: tokens.color.magicMint700,
//   backgroundPress: tokens.color.magicMint800,
// })

// const orange: BaseTheme = createTheme({
//   ...light,
//   background: tokens.color.givry100,
//   backgroundSoft: tokens.color.givry25,
//   backgroundStrong: tokens.color.givry200,
//   color: tokens.color.givry700,
//   placeholderColor: '#9D9D9D',
//   backgroundHover: tokens.color.givry200,
//   borderColor: tokens.color.givry400,
// })

// const gray: BaseTheme = createTheme({
//   ...light,
// })

// const light_Switch: BaseTheme = createTheme({
//   ...light,
//   background: tokens.color.perano100,
//   backgroundChecked: tokens.color.perano600,
//   borderColorFocus: tokens.color.perano500,
// })

// const light_SwitchThumb: BaseTheme = createTheme({
//   ...tamaguiThemes.light,
//   // backgroundSoft: tokens.color.perano300,
//   // background: tokens.color.perano900,
//   // color: tokens.color.perano50,

//   backgroundHover: tokens.color.perano25,
//   backgroundPress: tokens.color.perano800,
// })

// const allThemes = {
//   ...baseThemes,
//   // light,
//   // dark,
//   // secondary,
//   // red,
//   // green,
//   // orange,
//   // gray,
//   // light_Heading, // Heading
//   // dark_Heading, // Heading
//   // light_H1, // H1
//   // dark_H1, // H1
//   // light_H2, // H2
//   // dark_H2, // H2
//   // light_H3, // H3
//   // dark_H3, // H3
//   // Input,
//   // light_Input, // Input
//   // dark_Input, // Input
//   // light_Select, // Select
//   // dark_Select, // Select
//   // light_Button, // Button
//   // dark_Button, // Button
//   // red_Button, // Button
//   // green_Button, // Button
//   // light_Card,
//   // dark_Card,
//   // light_ListItem,
//   // dark_ListItem,
//   // light_Switch, // Switch
//   // light_SwitchThumb, // Switch Thumb
// }

// type ThemeName = keyof typeof allThemes

// export const themes: Themes = allThemes

// console.log({ allThemes })

const light = createTheme(palettes.light, lightTemplate, { nonInheritedValues: lightColors })

export const themes = {
  ...allThemes,

  light,
  dark: createTheme(palettes.dark, darkTemplate, { nonInheritedValues: darkColors }),
}
