import { AnimatePresence, Button as TButton, ButtonProps as TButtonProps, Spinner } from 'tamagui'

type ButtonProps = TButtonProps & {
  isLoading?: boolean
}

export const Button = (props: ButtonProps) => {
  const { isLoading, ...restProps } = props

  if (isLoading !== undefined) {
    restProps.iconAfter = (
      <AnimatePresence>
        {isLoading && (
          <Spinner
            color="$color"
            key="loading-spinner"
            opacity={1}
            y={0}
            animation="quick"
            enterStyle={{
              opacity: 0,
              y: 4,
            }}
            exitStyle={{
              opacity: 0,
              y: 4,
            }}
          />
        )}
      </AnimatePresence>
    )
  }

  return (
    <TButton
      // animation={isLoading ? null : 'bouncy'} => cause too many renders
      hoverStyle={{ scale: 0.98 }}
      pressStyle={{ scale: 0.98 }}
      {...restProps}
    />
  )
}
