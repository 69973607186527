import { api } from '@mps/pro-app/provider/react-query'
import { useSessionContext } from './supabase/useSessionContext'

export const useUser = () => {
  const { session, isLoading: isLoadingSession } = useSessionContext()
  const { data: user, isLoading: isLoadingUser } = api.user.current.useQuery(undefined, {
    enabled: !!session,
  })

  return {
    session,
    user,
    isLoadingSession,
    isLoadingUser,
    isLoading: isLoadingSession || isLoadingUser,
  }
}
