import {
  Button,
  Heading,
  Paragraph,
  TamaguiProvider,
  TamaguiProviderProps,
  Text,
  XStack,
  YStack,
} from '@mps/ui'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import React, { useEffect, useMemo } from 'react'

import { useRouter as useNextRouter } from 'next/navigation'
import { ErrorBoundary } from 'react-error-boundary'
import { Platform } from 'react-native'
import { useRouter } from 'solito/router'
import { Redirect } from '../features/core/components/Redirect'
import config from '../tamagui.config'
import { useUser } from '../utils/useUser'
import { AuthProvider } from './auth/AuthProvider'
import { QueryClientProvider } from './react-query'
import { StripeProvider } from './stripe'
import { ToastProvider } from './toast'

const RootErrorFallback = ({ error, resetErrorBoundary }) => {
  const nextRouter = useNextRouter()
  const supabase = createClientComponentClient()
  console.log('[RootErrorFallback] error', error, error.message, error.code, error.data)
  const redirectUrl = useMemo(() => {
    const errMsg = error?.message
    return errMsg.includes('redirectTo:') ? errMsg.replace('redirectTo:', '') : undefined
  }, [error?.message])

  console.log('[RootErrorFallback]', { redirectUrl, error })

  if (redirectUrl) {
    return <Redirect url={redirectUrl} onRedirect={resetErrorBoundary} />
  }

  async function signOut() {
    await supabase.auth.signOut()
    nextRouter.refresh()
  }

  if (error?.data?.code === 'UNAUTHORIZED') {
    signOut()
    return null
  }

  return (
    <>
      <YStack jc="center" ai="center" h={Platform.OS === 'web' ? '100vh' : '100%'} px="$6">
        <YStack>
          <Heading ta="center" mb="$6">
            Oups, une erreur est survenue...
          </Heading>
          {/* <Paragraph mb="$4">Erreur: {error.message}</Paragraph>
          <Paragraph mb="$4">Stack: {error.stack}</Paragraph> */}
          <Button onPress={resetErrorBoundary} mb="$2">
            Réessayer
          </Button>
          <Paragraph fontStyle="italic">
            Si le problème persiste essayez de rafraichir la page et/ou{' '}
            <XStack
              display="inline"
              onPress={() => {
                const w: any = window
                w?.$chatwoot?.toggle?.('open')
              }}
            >
              <Text
                fontFamily="$body"
                hoverStyle={{ cursor: 'pointer' }}
                fontWeight="500"
                color="$borderColor"
              >
                contacter le support
              </Text>
            </XStack>
            .
          </Paragraph>
        </YStack>
      </YStack>
    </>
  )
}

const RedirectUser = () => {
  const router = useRouter()
  const { user, isLoadingUser } = useUser()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    console.log('RedirectUser', { user, isLoadingUser })
    if (!isLoadingUser && user) {
      if (!user.firstName || !user.lastName || !user.phoneNumberVerifiedAt) {
        console.log('redirect to /inscription/informations')
        router.replace('/inscription/informations')
      } else if (!user.nurse || !user.nurse.officeId) {
        console.log('redirect to /inscription/cabinet')
        router.replace('/inscription/cabinet')
      } else if (!user.nurse.tour) {
        console.log('redirect to /inscription/zone')
        router.replace('/inscription/zone')
      } else if (!user.nurse?.tour?.preferredStartTime || !user.nurse?.tour?.preferredEndTime) {
        console.log('redirect to /inscription/disponibilites')
        router.replace({
          pathname: '/inscription/disponibilites',
          query: {
            tournee: user.nurse.tourId,
          },
        })
      }
    }
  }, [user, isLoadingUser])

  return null
}

export function Provider({
  children,
  defaultTheme,
  initialSession,
  getStripe,
}: Omit<TamaguiProviderProps, 'config'> & {
  children: any
  defaultTheme?: string
  initialSession?: any
  getStripe?: any
}) {
  return (
    <TamaguiProvider config={config} disableInjectCSS defaultTheme={defaultTheme ?? 'light'}>
      <ErrorBoundary
        key="root-error-boundary"
        FallbackComponent={RootErrorFallback}
        onError={(error, componentStack) => {
          // Sentry.captureException(error, { contexts: { react: { componentStack } } })
          console.log('[ErrorBoundary] error', error)
        }}
      >
        <AuthProvider initialSession={initialSession}>
          <ToastProvider>
            <QueryClientProvider>
              <StripeProvider getStripe={getStripe}>
                {children}
                <RedirectUser />
              </StripeProvider>
            </QueryClientProvider>
          </ToastProvider>
        </AuthProvider>
      </ErrorBoundary>
    </TamaguiProvider>
  )
}
