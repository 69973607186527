import {
  blue,
  blueDark,
  gray,
  grayDark,
  green,
  greenDark,
  orange,
  orangeDark,
  pink,
  pinkDark,
  purple,
  purpleDark,
  red,
  redDark,
  yellow,
  yellowDark,
} from '@tamagui/colors'
import { Variable, createTokens } from '@tamagui/web'

// should roughly map to button/input etc height at each level
// fonts should match that height/lineHeight at each stop
// so these are really non-linear on purpose
// why?
//   - at sizes <1, used for fine grained things (borders, smallest paddingY)
//     - so smallest padY should be roughly 1-4px so it can join with lineHeight
//   - at sizes >=1, have to consider "pressability" (jumps up)
//   - after that it should go upwards somewhat naturally
//   - H1 / headings top out at 10 naturally, so after 10 we can go upwards faster
//  but also one more wrinkle...
//  space is used in conjunction with size
//  i'm setting space to generally just a fixed fraction of size (~1/3-2/3 still fine tuning)
export const size = {
  $0: 0,
  '$0.25': 2,
  '$0.5': 4,
  '$0.75': 8,
  $1: 20,
  '$1.5': 24,
  $2: 28,
  '$2.5': 32,
  $3: 36,
  '$3.5': 40,
  $4: 44,
  $true: 44,
  '$4.5': 48,
  $5: 52,
  $6: 64,
  $7: 74,
  $8: 84,
  $9: 94,
  $10: 104,
  $11: 124,
  $12: 144,
  $13: 164,
  $14: 184,
  $15: 204,
  $16: 224,
  $17: 224,
  $18: 244,
  $19: 264,
  $20: 284,
}

type SizeKeysIn = keyof typeof size
type Sizes = {
  [Key in SizeKeysIn extends `$${infer Key}` ? Key : SizeKeysIn]: number
}
type SizeKeys = `${keyof Sizes extends `${infer K}` ? K : never}`

const spaces = Object.entries(size).map(([k, v]) => {
  return [k, sizeToSpace(v)] as const
})

// a bit odd but keeping backward compat for values >8 while fixing below
function sizeToSpace(v: number) {
  if (v === 0) return 0
  if (v === 2) return 0.5
  if (v === 4) return 1
  if (v === 8) return 1.5
  if (v <= 16) return Math.round(v * 0.333)
  return Math.floor(v * 0.7 - 12)
}

const spacesNegative = spaces.slice(1).map(([k, v]) => [`-${k.slice(1)}`, -v])

type SizeKeysWithNegatives =
  | Exclude<`-${SizeKeys extends `$${infer Key}` ? Key : SizeKeys}`, '-0'>
  | SizeKeys

export const space: {
  [Key in SizeKeysWithNegatives]: Key extends keyof Sizes ? Sizes[Key] : number
} = {
  ...Object.fromEntries(spaces),
  ...Object.fromEntries(spacesNegative),
} as any

export const zIndex = {
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
}

const peranoLight = {
  perano1: '#f2f4fc',
  perano2: '#eff2fe',
  perano3: '#e2e6fd',
  perano4: '#cbd1fa',
  perano5: '#a8b0f6',
  perano6: '#898bf0',
  perano7: '#726ce8',
  perano8: '#6250db',
  perano9: '#5442c0',
  perano10: '#45389b',
  perano11: '#3b337c',
  perano12: '#2f2a5c',
  // perano13: '#25214a',
  // perano14: '#1e1a3a',
}

const peranoDark = {
  perano1: '#1e1a3a',
  perano2: '#25214a',
  perano3: '#2f2a5c',
  perano4: '#3b337c',
  perano5: '#45389b',
  perano6: '#5442c0',
  perano7: '#6250db',
  perano8: '#726ce8',
  perano9: '#898bf0',
  perano10: '#a8b0f6',
  perano11: '#cbd1fa',
  perano12: '#e2e6fd',
  perano13: '#eff2fe',
  perano14: '#f2f4fc',
}

export const colorTokens = {
  light: {
    blue: blue,
    gray: gray,
    green: green,
    orange: orange,
    pink: pink,
    purple: purple,
    red: red,
    yellow: yellow,
    perano: peranoLight,
  },
  dark: {
    blue: blueDark,
    gray: grayDark,
    green: greenDark,
    orange: orangeDark,
    pink: pinkDark,
    purple: purpleDark,
    red: redDark,
    yellow: yellowDark,
    perano: peranoDark,
  },
}

// export const tokens = createTokens({
//   ...TamaguiTokens,
//   color: {
//     ...TamaguiTokens.color,
//     stTropaz50: '#f3f6fc',
//     stTropaz100: '#e6eef8',
//     stTropaz200: '#c7dbf0',
//     stTropaz300: '#96bce3',
//     stTropaz400: '#5e99d2',
//     stTropaz500: '#397dbe',
//     stTropaz600: '#255992',
//     stTropaz700: '#224e82',
//     stTropaz800: '#20446c',
//     stTropaz900: '#1f3a5b',
//     magicMint25: '#f7fdfd',
//     magicMint50: '#f1fcf9',
//     magicMint100: '#cff8eb',
//     magicMint200: '#97f0d5',
//     magicMint300: '#66e2c1',
//     magicMint400: '#36cba8',
//     magicMint500: '#1daf8f',
//     magicMint600: '#148d75',
//     magicMint700: '#15705f',
//     magicMint800: '#155a4d',
//     magicMint900: '#164b41',
//     magicMint1000: '#153d36',
//     perano25: '#f2f4fc',
//     perano50: '#eff2fe',
//     perano100: '#e2e6fd',
//     perano200: '#cbd1fa',
//     perano300: '#a8b0f6',
//     perano400: '#898bf0',
//     perano500: '#726ce8',
//     perano600: '#6250db',
//     perano700: '#5442c0',
//     perano800: '#45389b',
//     perano900: '#3b337c',
//     perano1000: '#2f2a5c',
//     perano1100: '#25214a',
//     perano1200: '#1e1a3a',
//     perano1300: '#18152b',
//     perano1400: '#12111d',
//     perano1500: '#0d0c11',
//     givry25: '#fdf7f5',
//     givry50: '#fdf4ef',
//     givry100: '#f8decf',
//     givry200: '#f3c9b5',
//     givry300: '#eca585',
//     givry400: '#e37754',
//     givry500: '#dc5533',
//     givry600: '#ce3f28',
//     givry700: '#ab2f23',
//     givry800: '#892823',
//     givry900: '#6f241f',
//   },
// })

export const darkColors = {
  ...colorTokens.dark.blue,
  ...colorTokens.dark.gray,
  ...colorTokens.dark.green,
  ...colorTokens.dark.orange,
  ...colorTokens.dark.pink,
  ...colorTokens.dark.purple,
  ...colorTokens.dark.red,
  ...colorTokens.dark.yellow,
  ...colorTokens.dark.perano,
}

export const lightColors = {
  ...colorTokens.light.blue,
  ...colorTokens.light.gray,
  ...colorTokens.light.green,
  ...colorTokens.light.orange,
  ...colorTokens.light.pink,
  ...colorTokens.light.purple,
  ...colorTokens.light.red,
  ...colorTokens.light.yellow,
  ...colorTokens.light.perano,
}

function postfixObjKeys<A extends { [key: string]: Variable<string> | string }, B extends string>(
  obj: A,
  postfix: B
): {
  [Key in `${keyof A extends string ? keyof A : never}${B}`]: Variable<string> | string
} {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [`${k}${postfix}`, v])) as any
}

export const color = {
  ...postfixObjKeys(lightColors, 'Light'),
  ...postfixObjKeys(darkColors, 'Dark'),
}

export const radius = {
  0: 0,
  1: 3,
  2: 5,
  3: 7,
  4: 9,
  true: 9,
  5: 10,
  6: 16,
  7: 19,
  8: 22,
  9: 26,
  10: 34,
  11: 42,
  12: 50,
}

export const tokens = createTokens({
  color,
  radius,
  zIndex,
  space,
  size,
})
