'use client'

import React from 'react'

import Script from 'next/script'

export default function Analytics() {
  return (
    <>
      <Script src="https://simple.pro.monpetitsoin.fr/latest.js" />
      <noscript>
        {/* eslint-disable @next/next/no-img-element */}
        <img
          src="https://simple.pro.monpetitsoin.fr/noscript.gif"
          alt=""
          referrerPolicy="no-referrer-when-downgrade"
        />
      </noscript>
    </>
  )
}
