import { useEffect, useState } from 'react'

import { useUser } from '@mps/pro-app/utils/useUser'
import { useMedia } from '@mps/ui'

const loadScript = (onLoad: () => void): boolean => {
  if (window.$chatwoot) return false
  ;((d: any, t: string) => {
    const BASE_URL = 'https://centre-aide.monpetitsoin.fr'
    const g = d.createElement(t)
    const s = d.getElementsByTagName(t)[0]
    g.src = BASE_URL + '/packs/js/sdk.js'
    g.defer = true
    g.async = true
    s.parentNode.insertBefore(g, s)
    g.async = !0
    g.onload = () => {
      window.chatwootSDK.run({
        websiteToken: '5SnBd23FjSeknBLuqbJbuaMM',
        baseUrl: BASE_URL,
      })
      onLoad()
    }
  })(document, 'script')
  return true
}

const ChatwootButton = () => {
  const { user, isLoadingUser } = useUser()
  const media = useMedia()
  const [isLoaded, setIsLoaded] = useState(false)

  const isOnboarded = !!user?.nurse?.tour?.preferredStartTime
  console.log({ isOnboarded })

  useEffect(() => {
    window.addEventListener('chatwoot:ready', () => {
      window.addEventListener('chatwoot:error', (error) => {
        console.log('chatwoot:error', error)
      })

      console.log('chatwoot:ready', { user, isLoadingUser })
      setIsLoaded(true)
      if (user?.email && user?.firstName && user?.lastName && !isLoadingUser) {
        console.log('chatwoot set user', {
          id: `${user.id}`,
          email: user.email,
          name: `${user?.firstName} ${user?.lastName}`,
        })
        window.$chatwoot.setUser(`${user.id}`, {
          email: user.email,
          name: `${user?.firstName} ${user?.lastName}`,
        })
        let plan = 'TRIAL'
        if (plan === 'TRIAL' && user?.trialEndsAt && user.trialEndsAt > new Date()) {
          plan = 'TRIAL_ENDED'
        } else if (user?.subscription?.status === 'active') {
          plan = 'PREMIUM'
        } else if (user?.subscription?.status === 'canceled') {
          plan = 'CANCELED'
        }
        console.log('chatwoot:plan', plan)
        window.$chatwoot.setCustomAttributes({
          signed_up_at: user.createdAt,
          plan,
          trialEndsAt: user?.trialEndsAt,
          subscribed_at: user?.subscription?.created,
          canceled_at: user?.subscription?.canceledAt,
        })
      }
    })

    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'none', // This can be left or right
      locale: 'fr', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
      darkMode: 'light',
    }

    console.log({ user, isLoadingUser })

    if (user && !isLoadingUser) {
      loadScript(() => {
        window.$chatwoot.toggle('close')
      })
    }
  }, [user, isLoadingUser])

  useEffect(() => {
    if (isLoaded && typeof window !== 'undefined') {
      const bubble = document.querySelector('.woot-widget-bubble')
      const bubbleIcon = document.querySelector('#woot-widget-bubble-icon')

      console.log({ bubble, sm: media.sm })

      if (media.sm) {
        if (bubbleIcon) {
          ;(bubbleIcon as any).style.height = '20px'
          ;(bubbleIcon as any).style.width = '20px'
          ;(bubbleIcon as any).style.margin = '12px'
        }

        if (bubble) {
          ;(bubble as any).style.height = '44px'
          ;(bubble as any).style.width = '44px'
          if (isOnboarded) {
            ;(bubble as any).style.transform = 'translate(14px, -66px)'
          }
        }
      }
    }
  }, [isLoaded, media.sm, isOnboarded])

  return null
}

export const SupportButton = () => {
  return <ChatwootButton />
}
