import { createMonaSansFont } from '@matthieuh/tamagui-font-mona-sans'

const heading = createMonaSansFont(
  {},
  {
    sizeSize: (size) => Math.round(size),
    sizeLineHeight: (size) => Math.round(size * 1.1 + (size < 30 ? 10 : 5)),
  }
)

const systemFamily =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

console.log({ systemFamily })

const body = createMonaSansFont(
  {
    family: systemFamily,
  },

  {
    sizeSize: (size) => Math.round(size),
    sizeLineHeight: (size) => Math.round(size * 1.1 + (size >= 12 ? 8 : 4)),
  }
)

export const fonts = {
  heading,
  body,
}
