import { styled } from '@tamagui/core'
import { Heading as HeadingTamagui } from 'tamagui'

export const Heading = styled(HeadingTamagui, {
  name: 'Heading',
  fontFamily: '$heading',
  size: '$11',
})

export const H1 = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  size: '$10',
  fontWeight: '700',
})

export const H2 = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  size: '$9',
  fontWeight: '600',
})

export const H3 = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  size: '$8',
})

export const H4 = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  size: '$7',
})

export const H5 = styled(Heading, {
  name: 'H5',
  tag: 'h5',
  size: '$6',
})

export const H6 = styled(Heading, {
  name: 'H6',
  tag: 'h6',
  size: '$5',
})
