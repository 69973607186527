const config = {
  title: 'Mon Petit Soin - Votre tournée infirmière facilitée',
  description: `Mon Petit Soin vous propose des soins infirmiers qui correspondent à vos disponibilités et à vos préférences. N'acceptez que des soins qui vous conviennent !`,
  openGraph: {
    type: 'website',
    locale: 'fr_FR',
    language: 'fr_FR',
    url: 'https://pro.monpetitsoin.fr',
    siteName: 'Mon Petit Soin',
  },
  twitter: {
    handle: '@monpetitsoin',
    site: '@monpetitsoin',
    cardType: 'summary_large_image',
  },
}

export default config
