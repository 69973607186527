import React, { createContext } from 'react'

interface StripeProviderProps {
  children: React.ReactNode
  getStripe: any
}

export interface StripeContextProps {
  getStripe: any
}

export const StripeContext = createContext<StripeContextProps>({
  getStripe: null,
})

export const StripeProvider: React.FC<StripeProviderProps> = ({ children, getStripe }) => {
  return <StripeContext.Provider value={{ getStripe }}>{children}</StripeContext.Provider>
}

export const useStripe = () => {
  const { getStripe } = React.useContext(StripeContext)
  return { getStripe }
}
