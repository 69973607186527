import { AppRouter } from '@mps/api'
import { QueryClient, QueryClientProvider as QueryClientProviderOG } from '@tanstack/react-query'
import { loggerLink } from '@trpc/client'
import { createTRPCReact, httpBatchLink } from '@trpc/react-query'
import { useEffect, useState } from 'react'
import SuperJSON from 'superjson'

export const api = createTRPCReact<AppRouter>()

const url = String(process.env.NEXT_PUBLIC_TRPC_API_URL)

export const QueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(
    new QueryClient({
      // web query config
    })
  )

  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        loggerLink({
          enabled: (op) =>
            process.env.NODE_ENV === 'development' ||
            (op.direction === 'down' && op.result instanceof Error),
        }),
        httpBatchLink({
          transformer: SuperJSON,
          url,
          fetch(url: string, options: Partial<RequestInit>) {
            return fetch(url, {
              ...options,
              credentials: 'include',
            })
          },
        }),
      ],
    })
  )

  const [isServer, setIsServer] = useState(true)

  useEffect(() => {
    setIsServer(false)
  }, [])

  if (isServer) return null

  return (
    <QueryClientProviderOG client={queryClient}>
      <api.Provider client={trpcClient} queryClient={queryClient}>
        {children}
      </api.Provider>
    </QueryClientProviderOG>
  )
}
