import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '@tamagui/core/reset.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'raf/polyfill'
import '../global.css'

import React from 'react'

import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import config from 'seo-configs/default.config'
import type { SolitoAppProps } from 'solito'

import { SupportButton } from '@mps/pro-app/features/support/components/SupportButton'
import { Provider } from '@mps/pro-app/provider'

import { api } from '@mps/pro-app/utils/api'
import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import Analytics from 'features/analytics/components/Analytics'
import { getStripe } from 'utils/stripe-client'

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css')
}

function MPSApp({
  Component,
  pageProps,
}: SolitoAppProps<{
  initialSession?: any
}>) {
  console.log('_app', { pageProps })
  return (
    <>
      <Head>
        {/* <title>Mon Petit Soin</title>
        <meta name="description" content="Votre tournée infirmière facilitée" /> */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
        <link rel="icon" href="/favicon.ico" />\
      </Head>
      <DefaultSeo {...config} />

      <Providers initialSession={pageProps?.initialSession}>
        <Component {...pageProps} />
      </Providers>

      <Analytics />
    </>
  )
}

const Providers = ({
  children,
  initialSession,
}: {
  children: React.ReactNode
  initialSession?: any
}) => {
  const [theme, setTheme] = useRootTheme()

  return (
    <NextThemeProvider
      onChangeTheme={(next) => {
        setTheme(next as any)
      }}
      forcedTheme="light"
    >
      <Provider
        disableRootThemeClass
        defaultTheme={theme}
        initialSession={initialSession}
        getStripe={getStripe}
      >
        <SupportButton />
        {children}
      </Provider>
    </NextThemeProvider>
  )
}

export default api.withTRPC(MPSApp)
