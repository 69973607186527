import { createTamagui } from 'tamagui'

import { createMedia } from '@tamagui/react-native-media-driver'
import { shorthands } from '@tamagui/shorthands'

import { animations } from './animations'
import { fonts } from './fonts'
import { themes } from './themes'
import { tokens } from './tokens'

export const media = createMedia({
  xs: { maxWidth: 660 },
  sm: { maxWidth: 800 },
  md: { maxWidth: 1020 },
  lg: { maxWidth: 1280 },
  xl: { maxWidth: 1420 },
  xxl: { maxWidth: 1600 },
  gtXs: { minWidth: 660 + 1 },
  gtSm: { minWidth: 800 + 1 },
  gtMd: { minWidth: 1020 + 1 },
  gtLg: { minWidth: 1280 + 1 },
  short: { maxHeight: 820 },
  tall: { minHeight: 820 },
  hoverNone: { hover: 'none' },
  pointerCoarse: { pointer: 'coarse' },
})

export const conf = {
  defaultFont: 'body',
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  animations,
  shorthands,
  fonts,
  themes,
  tokens,
  media,
} satisfies Parameters<typeof createTamagui>['0']

// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore - passing this directly breaks TS types
// conf.mediaQueryDefaultActive = {
//   xxs: false,
//   xs: true,
//   sm: true,
//   md: true,
//   lg: true,
//   xl: true,

//   gtXs: false,
//   gtSm: false,
//   gtMd: false,
//   gtLg: false,
//   gtXl: false,
// }

export const config = createTamagui(conf)
